export default {
  admin_noDataForSelectionParametars: 'Nema podataka za izabrane parametre',
  betslip_betslip: 'Tiket sa ulogom',
  betslip_maxBetAmount: 'Maks. iznos uloga je',
  betslip_maxLengthIs: 'Maks. dužina je',
  betslip_maxWinLimit: 'Ograničenje za maks. dobitak',
  betslip_minLengthIs: 'Min. dužina je',
  betslip_stake: 'Ulog',
  betslip_startBettingMessage: 'Dodajte opkladu da biste započeli',
  betslip_ticketResolving: 'Obrada tiketa',
  betslip_totalCombinations: 'Ukupno kombinacija',
  betslip_totalStake: 'Ukupni ulog',
  betslip_ticketAccepted: 'Tiket je prihvaćen',
  betslip_balanceWarning: 'Nema dovoljno kredita na vašem stanju.',
  betslip_maxTicketAmount: 'Maks. iznos na tiketu',
  betslip_minBetAmount: 'Min. iznos uloga',
  betslip_minTicketAmount: 'Min. iznos tiketa',
  betslip_stakePerBet: 'Ulog po opkladi',
  betslip_toReturn: 'Mogući dobitak',
  betslip_invalidDrawType: 'Nevažeći tip izvlačenja',
  betslip_invalidOutcomeNumbers: 'Nevažeći odigrani brojevi za izabrani tip ishoda',
  betslip_invalidStandardBet: 'Standardni ulog nije važeći',
  betslip_missingRequestUuid: 'Nedostaje uuid zahteva',
  betslip_numbersRange: 'Odigrani brojevi moraju biti u opsegu turnira',
  betslip_sameDrumBonusBall: 'Ne možete poslati bonus lopticu u isti bubanj',
  betslip_haveOnBetslip: 'Već postoji na betslipu',
  betslip_maxBetCountIs: 'Maksimalan broj opklada je',
  betslip_oddsHaveChangedFrom: 'Kvote su promenjene od',
  betslip_acceptAndPayin: 'Prihvaćeno i uplaćeno',
  betslip_oddsHaveChanged: 'Kvote su promenjene',
  betslip_outcomeIsAlready: 'Ishod je već dodat na betslip',
  game_NumbersBetting: 'Klađenje na brojeve',
  general_accept: 'Prihvati',
  general_all: 'Svi',
  general_bonus: 'Bonus',
  general_cancel: 'Poništi',
  general_check: 'Proveri',
  general_close: 'Zatvori',
  general_combo: 'Kombo',
  general_country: 'Zemlja',
  general_currentlyUnavailable: 'Klađenje na ovaj događaj trenutno nije dostupno',
  general_deleteShort: 'Brisanje',
  general_error: 'Greška',
  general_event: 'Runda',
  general_invalidTicketId: 'Pogrešan ID tiketa',
  general_lastTickets: 'Poslednji tiketi',
  general_lost: 'Gubitni',
  general_market: 'Ponuda',
  general_maxWinShort: 'Maks. dobitak',
  general_number: 'Broj',
  general_numbers: 'Brojevi',
  general_odd: 'Kvota',
  general_open: 'U igri',
  general_payIn: 'Uplata',
  general_payinTax: 'Porez na uplatu',
  general_payout: 'Isplata',
  general_payoutAmount: 'Suma za isplatu',
  general_payoutTax: 'Porez na isplatu',
  general_pick: 'Izaberite',
  general_possiblePayoutTax: 'Mogući porez na isplatu',
  general_possibleWin: 'Mogući dobitak',
  general_requiredField: 'Obavezno polje',
  general_results: 'Rezultati',
  general_search: 'Pretraži',
  general_selection: 'Izbor',
  general_signIn: 'Prijavite se',
  general_single: 'Single',
  general_ticket: 'Tiket',
  general_ticketCheck: 'Provera tiketa',
  general_time: 'Vreme',
  general_totalOdds: 'Ukupne kvote',
  general_winnings: 'Dobici',
  general_won: 'Osvojeno',
  general_today: 'Danas',
  general_clear: 'Obriši',
  general_edit: 'Uređivanje',
  general_reset: 'Resetuj',
  general_save: 'Sačuvaj',
  general_selectAll: 'Izaberi sve',
  general_totalTickets: 'Ukupno tiketa',
  general_pleaseWait: 'Sačekajte',
  general_add: 'Dodajte',
  general_ticketCode: 'Kod tiketa',
  general_updateBet: 'Ažuriraj ulog',
  general_payedout: 'Isplaćen',
  general_random: 'Nasumično',
  general_addBet: 'Dodajte ulog',
  general_more: 'Više',
  general_info: 'Info',
  general_successs: 'Uspešno',
  general_categories: 'Kategorije',
  general_others: 'Ostali',
  general_popular: 'Popularno',
  general_success: 'Uspešno',
  general_ticketId: 'ID tiketa',
  general_all_countries: 'Sve zemlje',
  general_popular_lottery: 'Popularna izvlačenja',
  general_tournamentCancelled: 'Turnir otkazan',
  general_in_play: 'U igri',
  general_warning: 'Upozorenje',
  general_clearAll: 'Izbriši sve',
  general_refresh: 'Osveži',
  general_sortByCategories: 'Po kategorijama',
  general_sortByTime: 'Po vremenu',
  general_translations: 'Prevodi',
  general_to: 'Do',
  general_betID: 'ID opklade',
  general_eventID: 'ID runde',
  general_outcomeID: 'ID ishoda',
  general_betslip: 'Tiket',
  general_bonus_balls: 'Bonus kugle',
  general_pay_in: 'Uplata',
  general_stake_per_bet: 'Jednak ulog',
  general_back: 'Nazad',
  general_bet: 'Opklada',
  general_match_locked: 'Izvlačenje zaključano',
  general_name: 'Ime',
  general_offer: 'Ponuda',
  general_selectAnOption: 'Izaberi opciju',
  general_selectionContent: 'Izaberi sadržaj',
  general_type: 'Tip',
  general_noDataAvailable: 'Nema podataka za izabrane parametre',
  general_oddsMgmt: 'Menadžment kvota',
  general_quickPick: 'Brzi odabir',
  general_internalError: 'Interna greška',
  general_status: 'Status',
  general_id: 'ID',
  general_deleteLockedBets: 'Izbriši zaključane opklade?',
  general_acceptAndProceed: 'Prihvati i nastavi',
  general_min_payment_per_ticket_rule: 'Minimalna isplata po tiketu je {value}. ',
  general_max_payment_per_ticket_rule: 'Maksimalna isplata po tiketu je {value}.',
  general_max_bet_amount_rule: 'Maksimalan iznos opklade je {value}.',
  general_min_bet_amount_rule: 'Minimalan iznos opklade je {value}.',
  general_noInternet: 'It appears you are offline. Please check your internet connection.',
  general_application: 'Aplikacija',
  general_channel: 'Kanal',
  general_category: 'Kategorija',
  general_tournament: 'Turnir',
  general_player: 'Igrač',
  general_operator: 'Operater',
  general_betshop: 'Poslovnica',
  general_hour: 'Sat',
  general_week: 'Nedelja',
  general_month: 'Mesec',
  general_maxPaymentAmount: 'Maksimalni iznos uplate',
  general_maxTicketCount: 'Maksimalni broj tiketa',
  general_maxSameNumbersSelection: 'Maksimalni broj oklada sa istim brojevima',
  general_futureBets: 'Future bets',
  general_future_bet: 'Future bet',
  general_stake_with_future_bets: 'Stake with all future bets',
  nb_bonusBall: 'Bonus loptica',
  shop_betIsNotFound: 'Opklada nije pronađena',
  shop_outcomeIsNotInOffer: 'Ishod nije u ponudi',
  shop_matchWithId: 'Runda ID',
  shop_notfoundAlreadyStarted: 'Nije pronađena ili je počela',
  shop_nextEventStartingIn: 'Sledeća runda startuje za',
  shop_allEventsStarted: 'All events have already started',
  shop_webCodeInvalidOrExpired: 'Web code is invalid or has expired',
  shop_newTicket: 'Novi tiket',
  shop_print: 'Štampaj',
  shop_results: 'Rezultati',
  shop_random: 'Nasumično',
  shop_fullOffer: 'Kompletna ponuda',
  shop_resetTicket: 'Poništi tiket',
  shop_ticketType: 'Vrsta tiketa',
  shop_offerResultsPrint: 'Ponuda/rezultati štampanje',
  shop_printPreview: 'Pregled štampe',
  shop_offerSelect: 'Odabir ponude za štampanje',
  shop_resultsSelect: 'Odabir rezultata za štampanja',
  shop_bettingDisabled: 'Klađene nedostupno',
  shop_printOffer: 'Štampanje ponude',
  shop_resultsOffer: 'Ponuda rezultata',
  shop_removeDuplicateNumbers: 'Ukloni duple brojeve',
  shop_printPreviewIsGenerating: 'Sačekajte, štampanje se generiše',
  shop_sameLocationPayoutRestriction: 'Tiket može biti isplaćen samo na uplatnom mestu gde je odigran',
  shop_unknownTicketCheck: 'Nepoznat tiket',
  shop_addToBetslip: 'Add to betslip',
  shop_removeBallsSelection: 'Remove balls selection',
  general_system: 'Sistem',
};
